// Import styles
@import "/node_modules/@coreui/coreui/scss/variables";

// Variable overrides
$navbar-bg:                           $gray-800;

$navbar-color:                        $gray-400; // $gray-600 !default;
$navbar-hover-color:                  $gray-200; // $gray-800 !default;
$navbar-active-color:                 $gray-200; // $gray-800 !default;
$navbar-disabled-color:               $gray-700; // $gray-300 !default;

$navbar-toggler-icon-hover:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");

$navbar-border: (
  bottom: (
    size: 0
  )
)
