//
// Improve agGrid bootstrap theme
//
$ag-icons-path: '/node_modules/ag-grid-community/src/styles/ag-theme-base/icons/';

$font-family: var(--font-family-sans-serif);
$font-weight: 400;

$secondary-font-family: var(--font-family-sans-serif);
$secondary-font-weight: 700;

$grid-size-regular: 6px;
$grid-size-small: 4px;

$row-height-regular: $grid-size-regular * 6 + 4; // 40px
$row-height-small: $grid-size-small * 6 + 4; // 28px

$header-height-regular: $row-height-regular;
$header-height-small: $row-height-small;

// @import '~/ag-grid-community/src/styles/ag-grid.scss';
@import '/node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '/node_modules/ag-grid-community/src/styles/ag-theme-bootstrap/sass/ag-theme-bootstrap.scss';

.ag-theme-bootstrap {
  margin-bottom: 1rem;

  .ag-root {
    border: 0;
  }

  .ag-header-group-cell-with-group {
    border-bottom: 0;
  }

  .ag-header {
    border-bottom: 1px solid $table-border-color;

    .ag-header-row,
    .ag-header-cell {
      border: 0;
      cursor: pointer;
    }
    .ag-header-cell {
      border-top: 1px solid $table-border-color;
    }
    .ag-header-cell.p-0 {
      .ag-header-cell-label {
        width: 100%;
      }
    }
    .ag-header-cell.ag-header-centered, .ag-header-group-cell.ag-header-centered {
      .ag-header-cell-label {
        justify-content: center;
      }
      .ag-header-group-cell-label {
        justify-content: center;
      }
    }
  }

  // By default the agGrid row stripes are the opposite way round from the bootstrap ones. You can customize this in Bootstrap to match agGrid by setting a variable, or we can fix it here.
  .ag-row-even {
    // background-color: $table-accent-bg;
    // Should be this ^^, but that gets you #EAEAEA,
    // which isn't what bootstrap seems to use???
    background-color: #f2f2f2;
  }
  .ag-row-odd {
    background-color: $white;
  }

  .ag-row-selected {
    background-color: #bde2e5;
  }

  .ag-cell {
    border-top: 1px solid $table-border-color;
  }

  .ag-cell.with-error {
    padding-left: 20px !important;
  }
    .ag-cell.with-error:before {
      position: absolute;
      font-family: 'Font Awesome 6 Pro';
      top: 0;
      left: 0px;
      content: "\f06a";
      font-weight: bold;
      color: red;
    }

  .ag-ltr .ag-cell-focus {
    border: 1px dotted $table-border-color;
  }
}

// Regular grid size
.ag-grid-size-regular {
  > .ag-theme-bootstrap {
    .ag-header {
      .ag-header-row,
      .ag-header-cell {
        // Set the height from the grid element because ag-grid adds style tag
        // and does some additional code changes to set the height of the elements
        // setting the height here even with important! is not enough
        // height: $header-height;
        line-height: $header-height-regular;
      }
    }
    .ag-header-cell-menu-button {
      .ag-icon-menu {
        height: $row-height-regular;
      }
    }
    .ag-cell {
      line-height: $row-height-regular;
    }
    .ag-cell-inline-editing {
      height: $row-height-regular;
      min-height: $row-height-regular;
    }
    .ag-body-viewport.ag-layout-auto-height {
      .ag-center-cols-clipper {
        min-height: $row-height-regular;
      }
      .ag-center-cols-container {
        min-height: $row-height-regular;
      }
    }
  }
}

// Small grid size
.ag-grid-size-small {
  .ag-theme-bootstrap {
    .ag-header {
      .ag-header-row,
      .ag-header-cell {
        // Set the height from the grid element because ag-grid adds style tag
        // and does some additional code changes to set the height of the elements
        // setting the height here even with important! is not enough
        // height: $header-height;
        line-height: $header-height-small;
      }
    }
    .ag-header-cell-menu-button {
      .ag-icon-menu {
        height: $row-height-small;
      }
    }
    .ag-cell {
      line-height: $row-height-small;
    }
    .editable-cell {
      box-shadow: rgba(0, 0, 0, 0.2) 0 1px 4px 0;
    }
    .disabled-cell {
      background-color: rgba(200, 200, 200, 0.4);
      border: none !important;
    }
    .edited-cell {
      background-color: rgba(255, 255, 0, 0.4);
      font-weight: bold;
    }
    .ag-cell-inline-editing {
      height: $row-height-small;
      min-height: $row-height-small;
    }
    .ag-body-viewport.ag-layout-auto-height {
      .ag-center-cols-clipper {
        min-height: $row-height-small;
      }
      .ag-center-cols-container {
        min-height: $row-height-small;
      }
    }
  }
}

.header-cell-no-border-top {
  .ag-theme-bootstrap {
    .ag-header {
      .ag-header-cell {
          border-top: 0;
      }
    }
  }
}


.no-body-top-padding .modal-body {
  padding-top: 0;
}

.ag-root-wrapper > .ag-theme-bootstrap {
  margin-bottom: 0;
}
