@import "variables";
// Global styles

.bg-invalid {
  background-color: #ffc7ce; // pink-ish
  color: #23282c !important;
}

// Chrome
@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    position: unset;
    top: unset;
    left: unset;
    margin-left: unset;
  }
}

.navbar .navbar-brand {
  width: auto;
}

.navbar-nav .app-title-centered {
  color: $navbar-active-color;
}

app-infra-panel > .card {
  min-height: 132px;
}

// Forms
.card-header.card-header-small {
  padding: 0.4rem 0.75rem;
}

.card-header > .card-header-centered {
  text-align: center;
}

.card-header-action:disabled:hover {
  color: $gray-600;
}

.card-footer > .lg-line {
  line-height: 2em;
}

.card-footer > .btn {
  margin-right: 5px;
}

.card.card-transparent {
  background-color: transparent;
  border: none;
}

// dashed border elements; add one element for each side and parent has to be relative
.horizontal-dashed {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  background-image: linear-gradient(to left, black 33%, rgba(255,255,255,0) 0%);
  background-size: 25px 1px;
  background-repeat: repeat-x;
}

.horizontal-dashed.top {
  top: 0;
}
.horizontal-dashed.bottom {
  bottom: 0;
}

.vertical-dashed {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  background-image: linear-gradient(black 33%, rgba(255,255,255,0) 0%);
  background-size: 1px 25px;
  background-repeat: repeat-y;
}

.vertical-dashed.left {
  left: 0;
}

.vertical-dashed.right {
  right: 0;
}

// ng-select
.ng-select.form-control {
  padding: 0;
  height: auto;
}
.ng-select.form-control > .ng-select-container {
  min-height: 33px;
  border: none;
}
.ng-select.form-control.ng-select-focused {
  border-color: #8ad4ee;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}
.ng-select.form-control.ng-select-focused.is-invalid {
  border-color: #f86c6b;
  box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
}
.ng-select.ng-select-single > .ng-select-container {
    height: auto;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e4e7ea;
}
// hide disabled options
.ng-select > .ng-dropdown-panel .ng-option.ng-option-disabled {
  display: none !important;
}
// ng-select

// app-tooltip
.tooltip-custom-content {
  min-width: max-content;
}
.tooltip-component {
  position: relative;
}
// tooltip icon positions
.tooltip-icon-inside {
  right: 4px;
  top: 4px;
}
.tooltip-icon-outside {
  right: -20px;
  top: 4px;
}
.tooltip-icon-size i {
  font-size: $font-size-base;
}
.tooltip-icon-next-to {
  right: -18px;
  top: -12px;
}
// app-tooltip

.col-static {
    flex: 0 0 auto;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
}

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.white-space-preserve {
  white-space: pre;
}

mv-slider.w-100 > .slider-horizontal {
  width: 100%;
}

.main > .container-fluid > router-outlet + *:not(ngx-alert) {
    display: block;
}

.main > .container-fluid > router-outlet + *:not(ngx-alert) > .animated > .card:last-child {
    margin-bottom: 0;
}

body > .modal-backdrop + .modal-backdrop {
  z-index: 1050;
}

// dropdown
.dropdown-item:active {
  color: #ffffff !important;
}

.dropdown-item.disabled {
  background-color: #e4e7ea;
  color: #73818f !important; // text-muted
}

.inner-dialog .modal {
  z-index: 1051;
}

.scrollable {
  overflow-y: scroll;
}

.form-control.is-invalid ~ .invalid-tooltip.show-on-hover {
  display: none;
}
.form-control.is-invalid:hover ~ .invalid-tooltip.show-on-hover {
  display: block;
}

.big-checkbox {
  width: 1.15em;
  height: 1.15em;
}

.nav-item.disabled {
  background-color: #e4e7ea;
}


.flex {
  flex: 1;
  min-width: 0;
}

.flex-none {
  flex: 0 0 auto;
}

.warnings-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .warnings-title {
    font-weight: bold;
  }
  .warnings-list {
    margin: 0;
    padding: 0 15px;
    li {
      margin: 5px;
      text-align: left;
    }
  }
}

.tooltip {
  .tooltip-inner {
    max-width: 380px;
  }
}

.label-disabled {
  cursor: default;
}

// switch-slider
.switch-input:disabled ~ .switch-slider {
  opacity: 1.0;
  background-color: #e4e7ea;
  cursor: default;

  &::before {
    background-color: #e4e7ea;
  }
}

.switch-input:checked:disabled ~ .switch-slider {
  opacity: 0.65;
}

// Fix rendering issue: https://pds-pe.visualstudio.com/WITSML/_workitems/edit/54578
.switch-label .switch-slider::before {
  z-index: auto;
}

.switch-label .switch-slider::after {
  z-index: auto;
}

.btn.disabled, .btn:disabled {
  cursor: default;
}

.dropdown-header-logged-in {
  @extend .text-wrap; 
  width: 250px;
}

.scrollable {
  overflow-y: auto;
}

.app-body, .main {
  height: calc(100vh - 55px - 50px);

  @extend .scrollable;
}

.tb {
  background-color: transparent;
}

.date-picker-footer {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 3px;
}

.date-picker-footer button:hover{
  color: white;
}

/*https://github.com/ag-grid/ag-grid/issues/2911*/
.ag-center-cols-viewport::-webkit-scrollbar {
  height: 0px;
}