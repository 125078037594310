// Bootstrap / CoreUI Variables
@import "variables";

// FontAwesome
@import "/node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "/node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "/node_modules/@fortawesome/fontawesome-pro/scss/solid";

// NGX Bootstrap
@import "/node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// NG Select 
@import "/node_modules/@ng-select/ng-select/scss/ng-select.component";
@import "/node_modules/@ng-select/ng-select/scss/default.theme";

// Bootstrap Slider
@import "/node_modules/bootstrap-slider/dist/css/bootstrap-slider";

// CoreUI
@import "/node_modules/@coreui/coreui/scss/coreui";

// AG Grid
@import "/node_modules/ag-grid-community/dist/styles/ag-grid";
@import "/node_modules/ag-grid-community/dist/styles/ag-theme-bootstrap";

@import "ag-grid-bootstrap-custom";

// Pretty Print JSON
@import "/node_modules/pretty-print-json/dist/css/pretty-print-json.min.css";

// Dr.Q Custom Global Styles
@import "custom";
